<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Pedigree</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="openPedigree(dog)"
                  >
                    Save pedigree
                  </v-btn>
                </template>
                <span>Save pedigree</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <div class="generate_pedigree">
              <a class="vh-button" id="generatePed"></a>

              <div class="place_for_message">&nbsp;</div>

              <table id="pedigree">
                <tbody>
                  <tr>
                    <td rowspan="16">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 1, 0)"
                          hide-no-data
                          variant="outlined"
                          label="Dam"
                          v-model="fields.pedigreeArray_0_0"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_0_0')"
                          :error-messages="errors['pedigreeArray_0_0']"
                        >
                          <!-- <template v-slot:selection="{ item }">
                            {{ `${item.kennel_name} - ${item.name}` }}
                          </template> -->

                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_0_0',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template>
                        </v-autocomplete>
                      </div>
                    </td>
                    <td rowspan="8">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 2, 0)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_1_0_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_1_0"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_1_0')"
                          :error-messages="errors['pedigreeArray_1_0']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_1_0',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td rowspan="4">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 3, 0)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_2_0_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_2_0"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_2_0')"
                          :error-messages="errors['pedigreeArray_2_0']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_2_0',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 0)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_0_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_3_0"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_0')"
                          :error-messages="errors['pedigreeArray_3_0']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_0',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_0_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_0"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_0')"
                          :error-messages="errors['pedigreeArray_4_0']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_0',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_1_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_1"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_1')"
                          :error-messages="errors['pedigreeArray_4_1']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_1',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 2)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_1_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_3_1"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_1')"
                          :error-messages="errors['pedigreeArray_3_1']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_1',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_2_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_2"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_2')"
                          :error-messages="errors['pedigreeArray_4_2']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_2',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_3_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_3"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_3')"
                          :error-messages="errors['pedigreeArray_4_3']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_3',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="4">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 3, 2)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_2_1_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_2_1"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_2_1')"
                          :error-messages="errors['pedigreeArray_2_1']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_2_1',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 4)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_2_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_3_2"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_2')"
                          :error-messages="errors['pedigreeArray_3_2']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_2',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_4_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_4"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_4')"
                          :error-messages="errors['pedigreeArray_4_4']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_4',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_5_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_5"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_5')"
                          :error-messages="errors['pedigreeArray_4_5']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_5',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 6)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_3_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_3_3"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_3')"
                          :error-messages="errors['pedigreeArray_3_3']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_3',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_6_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_6"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_6')"
                          :error-messages="errors['pedigreeArray_4_6']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_6',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_7_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_7"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_7')"
                          :error-messages="errors['pedigreeArray_4_7']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_7',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="8">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 2, 2)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_1_1_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_1_1"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_1_1')"
                          :error-messages="errors['pedigreeArray_1_1']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_1_1',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td rowspan="4">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 3, 4)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_2_2_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_2_2"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_2_2')"
                          :error-messages="errors['pedigreeArray_2_2']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_2_2',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 8)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_4_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_3_4"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_4')"
                          :error-messages="errors['pedigreeArray_3_4']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_4',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_8_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_8"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_8')"
                          :error-messages="errors['pedigreeArray_4_8']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_8',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_9_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_9"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_9')"
                          :error-messages="errors['pedigreeArray_4_9']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_9',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 10)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_5_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_3_5"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_5')"
                          :error-messages="errors['pedigreeArray_3_5']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_5',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_10_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_10"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_10')"
                          :error-messages="errors['pedigreeArray_4_10']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_10',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_11_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_11"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_11')"
                          :error-messages="errors['pedigreeArray_4_11']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_11',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="4">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 3, 6)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_2_3_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_2_3"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_2_3')"
                          :error-messages="errors['pedigreeArray_2_3']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_2_3',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 12)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_6_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_3_6"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_6')"
                          :error-messages="errors['pedigreeArray_3_6']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_6',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_12_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_12"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_12')"
                          :error-messages="errors['pedigreeArray_4_12']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_12',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_13_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_13"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_13')"
                          :error-messages="errors['pedigreeArray_4_13']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_13',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 14)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_7_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_3_7"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_7')"
                          :error-messages="errors['pedigreeArray_3_7']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_7',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_14_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_14"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_14')"
                          :error-messages="errors['pedigreeArray_4_14']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_14',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_15_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_15"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_15')"
                          :error-messages="errors['pedigreeArray_4_15']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_15',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="16">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 1, 2)"
                          hide-no-data
                          label="Sire"
                          v-model="fields.pedigreeArray_0_1"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_0_1')"
                          :error-messages="errors['pedigreeArray_0_1']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_0_1',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td rowspan="8">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 2, 4)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_1_2_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_1_2"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_1_2')"
                          :error-messages="errors['pedigreeArray_1_2']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_1_2',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td rowspan="4">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 3, 8)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_2_4_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_2_4"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_2_4')"
                          :error-messages="errors['pedigreeArray_2_4']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_2_4',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 16)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_8_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_3_8"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_8')"
                          :error-messages="errors['pedigreeArray_3_8']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_8',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_16_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_16"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_16')"
                          :error-messages="errors['pedigreeArray_4_16']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_16',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_17_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_17"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_17')"
                          :error-messages="errors['pedigreeArray_4_17']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_17',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 18)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_9_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_3_9"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_9')"
                          :error-messages="errors['pedigreeArray_3_9']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_9',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_18_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_18"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_18')"
                          :error-messages="errors['pedigreeArray_4_18']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_18',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_19_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_19"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_19')"
                          :error-messages="errors['pedigreeArray_4_19']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_19',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="4">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 3, 10)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_2_5_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_2_5"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_2_5')"
                          :error-messages="errors['pedigreeArray_2_5']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_2_5',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 20)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_10_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_3_10"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_10')"
                          :error-messages="errors['pedigreeArray_3_10']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_10',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_20_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_20"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_20')"
                          :error-messages="errors['pedigreeArray_4_20']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_20',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_21_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_21"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_21')"
                          :error-messages="errors['pedigreeArray_4_21']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_21',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 22)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_11_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_3_11"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_11')"
                          :error-messages="errors['pedigreeArray_3_11']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_11',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_22_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_22"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_22')"
                          :error-messages="errors['pedigreeArray_4_22']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_22',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_23_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_23"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_23')"
                          :error-messages="errors['pedigreeArray_4_23']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_23',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="8">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 2, 6)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_1_3_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_1_3"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_1_3')"
                          :error-messages="errors['pedigreeArray_1_3']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_1_3',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td rowspan="4">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 3, 12)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_2_6_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_2_6"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_2_6')"
                          :error-messages="errors['pedigreeArray_2_6']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_2_6',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 24)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_12_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_3_12"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_12')"
                          :error-messages="errors['pedigreeArray_3_12']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_12',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_24_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_24"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_24')"
                          :error-messages="errors['pedigreeArray_4_24']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_24',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_25_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_25"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_25')"
                          :error-messages="errors['pedigreeArray_4_25']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_25',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 26)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_13_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_3_13"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_13')"
                          :error-messages="errors['pedigreeArray_3_13']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_13',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_26_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_26"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_26')"
                          :error-messages="errors['pedigreeArray_4_26']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_26',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_27_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_27"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_27')"
                          :error-messages="errors['pedigreeArray_4_27']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_27',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="4">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 3, 14)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_2_7_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_2_7"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_2_7')"
                          :error-messages="errors['pedigreeArray_2_7']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_2_7',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 28)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_14_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_3_14"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_14')"
                          :error-messages="errors['pedigreeArray_3_14']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_14',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_28_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_28"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_28')"
                          :error-messages="errors['pedigreeArray_4_28']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_28',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_29_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_29"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_29')"
                          :error-messages="errors['pedigreeArray_4_29']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_29',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      <div class="button_select">
                        <v-autocomplete
                          @change="updateParent($event, 4, 30)"
                          hide-no-data
                          :disabled="fields.pedigreeArray_3_15_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_3_15"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_3_15')"
                          :error-messages="errors['pedigreeArray_3_15']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_3_15',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_30_Disabled"
                          label="Dam"
                          v-model="fields.pedigreeArray_4_30"
                          :items="dams"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_30')"
                          :error-messages="errors['pedigreeArray_4_30']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_30',
                                    'F'
                                  )
                                "
                                >Add New Dam</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="button_select">
                        <v-autocomplete
                          hide-no-data
                          :disabled="fields.pedigreeArray_4_31_Disabled"
                          label="Sire"
                          v-model="fields.pedigreeArray_4_31"
                          :items="sires"
                          item-text="kennel_name"
                          return-object
                          item-value="id"
                          outlined
                          background-color="white"
                          :error="errors.hasOwnProperty('pedigreeArray_4_31')"
                          :error-messages="errors['pedigreeArray_4_31']"
                        >
                          <template v-slot:prepend-item>
                            <div class="append">
                              <v-btn
                                @click="
                                  $refs.dogForm.openForm(
                                    'pedigreeArray_4_31',
                                    'M'
                                  )
                                "
                                >Add New Sire</v-btn
                              >
                            </div>
                          </template></v-autocomplete
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        v-model="confirmSave.dialog"
        persistent
        max-width="400"
        :retain-focus="false"
      >
        <v-card>
          <v-card-title class="headline">Confirm</v-card-title>
          <v-card-text>Are you sure you want to save?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetPedigree"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="confirmSave.loading"
              @click="savePedigree"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <DogForm ref="dogForm" :pedigree="this.fields" />
    </v-container>
  </div>
</template>

<script>
import DogForm from "../components/AddNewPedigree";

export default {
  components: { DogForm },

  data() {
    return {
      search: null,
      searchInput: "",
      i: 0,
      fields: {
        sire_id: null,
        dam_id: null,
        pedigreeArray_0_0: null,
        pedigreeArray_0_1: null,

        pedigreeArray_1_0: null,
        pedigreeArray_1_1: null,
        pedigreeArray_1_2: null,
        pedigreeArray_1_3: null,

        pedigreeArray_2_0: null,
        pedigreeArray_2_1: null,
        pedigreeArray_2_2: null,
        pedigreeArray_2_3: null,
        pedigreeArray_2_4: null,
        pedigreeArray_2_5: null,
        pedigreeArray_2_6: null,
        pedigreeArray_2_7: null,

        pedigreeArray_3_0: null,
        pedigreeArray_3_1: null,
        pedigreeArray_3_2: null,
        pedigreeArray_3_3: null,
        pedigreeArray_3_4: null,
        pedigreeArray_3_5: null,
        pedigreeArray_3_6: null,
        pedigreeArray_3_7: null,
        pedigreeArray_3_8: null,
        pedigreeArray_3_9: null,
        pedigreeArray_3_10: null,
        pedigreeArray_3_11: null,
        pedigreeArray_3_12: null,
        pedigreeArray_3_13: null,
        pedigreeArray_3_14: null,
        pedigreeArray_3_15: null,

        pedigreeArray_4_0: null,
        pedigreeArray_4_1: null,
        pedigreeArray_4_2: null,
        pedigreeArray_4_3: null,
        pedigreeArray_4_4: null,
        pedigreeArray_4_5: null,
        pedigreeArray_4_6: null,
        pedigreeArray_4_7: null,
        pedigreeArray_4_8: null,
        pedigreeArray_4_9: null,
        pedigreeArray_4_10: null,
        pedigreeArray_4_11: null,
        pedigreeArray_4_12: null,
        pedigreeArray_4_13: null,
        pedigreeArray_4_14: null,
        pedigreeArray_4_15: null,

        pedigreeArray_4_16: null,
        pedigreeArray_4_17: null,
        pedigreeArray_4_18: null,
        pedigreeArray_4_19: null,
        pedigreeArray_4_20: null,
        pedigreeArray_4_21: null,
        pedigreeArray_4_22: null,
        pedigreeArray_4_23: null,
        pedigreeArray_4_24: null,
        pedigreeArray_4_25: null,
        pedigreeArray_4_26: null,
        pedigreeArray_4_27: null,
        pedigreeArray_4_28: null,
        pedigreeArray_4_29: null,
        pedigreeArray_4_30: null,
        pedigreeArray_4_31: null,

        pedigreeArray_0_0_Disabled: true,
        pedigreeArray_0_1_Disabled: true,

        pedigreeArray_1_0_Disabled: true,
        pedigreeArray_1_1_Disabled: true,
        pedigreeArray_1_2_Disabled: true,
        pedigreeArray_1_3_Disabled: true,

        pedigreeArray_2_0_Disabled: true,
        pedigreeArray_2_1_Disabled: true,
        pedigreeArray_2_2_Disabled: true,
        pedigreeArray_2_3_Disabled: true,
        pedigreeArray_2_4_Disabled: true,
        pedigreeArray_2_5_Disabled: true,
        pedigreeArray_2_6_Disabled: true,
        pedigreeArray_2_7_Disabled: true,

        pedigreeArray_3_0_Disabled: true,
        pedigreeArray_3_1_Disabled: true,
        pedigreeArray_3_2_Disabled: true,
        pedigreeArray_3_3_Disabled: true,
        pedigreeArray_3_4_Disabled: true,
        pedigreeArray_3_5_Disabled: true,
        pedigreeArray_3_6_Disabled: true,
        pedigreeArray_3_7_Disabled: true,
        pedigreeArray_3_8_Disabled: true,
        pedigreeArray_3_9_Disabled: true,
        pedigreeArray_3_10_Disabled: true,
        pedigreeArray_3_11_Disabled: true,
        pedigreeArray_3_12_Disabled: true,
        pedigreeArray_3_13_Disabled: true,
        pedigreeArray_3_14_Disabled: true,
        pedigreeArray_3_15_Disabled: true,

        pedigreeArray_4_0_Disabled: true,
        pedigreeArray_4_1_Disabled: true,
        pedigreeArray_4_2_Disabled: true,
        pedigreeArray_4_3_Disabled: true,
        pedigreeArray_4_4_Disabled: true,
        pedigreeArray_4_5_Disabled: true,
        pedigreeArray_4_6_Disabled: true,
        pedigreeArray_4_7_Disabled: true,
        pedigreeArray_4_8_Disabled: true,
        pedigreeArray_4_9_Disabled: true,
        pedigreeArray_4_10_Disabled: true,
        pedigreeArray_4_11_Disabled: true,
        pedigreeArray_4_12_Disabled: true,
        pedigreeArray_4_13_Disabled: true,
        pedigreeArray_4_14_Disabled: true,
        pedigreeArray_4_15_Disabled: true,

        pedigreeArray_4_16_Disabled: true,
        pedigreeArray_4_17_Disabled: true,
        pedigreeArray_4_18_Disabled: true,
        pedigreeArray_4_19_Disabled: true,
        pedigreeArray_4_20_Disabled: true,
        pedigreeArray_4_21_Disabled: true,
        pedigreeArray_4_22_Disabled: true,
        pedigreeArray_4_23_Disabled: true,
        pedigreeArray_4_24_Disabled: true,
        pedigreeArray_4_25_Disabled: true,
        pedigreeArray_4_26_Disabled: true,
        pedigreeArray_4_27_Disabled: true,
        pedigreeArray_4_28_Disabled: true,
        pedigreeArray_4_29_Disabled: true,
        pedigreeArray_4_30_Disabled: true,
        pedigreeArray_4_31_Disabled: true,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      confirmSave: {
        dialog: false,
        dog: {},
        loading: false,
      },
    };
  },

  mounted: function () {
    var array = [];
    this.emailTo = this.emailToModel;

    this.pedigreeArray.forEach((num1, index) => {
      num1.forEach((num2, index2) => {
        var nextGeneration = index + 1;
        var nextChild = index2 * 2;

        var sireNextChild = nextChild + 1;

        this.fields["pedigreeArray_" + index + "_" + index2] = num2;

        this.fields[
          "pedigreeArray_" + index + "_" + index2 + "_Disabled"
        ] = false;

        //girl
        this.fields[
          "pedigreeArray_" + nextGeneration + "_" + nextChild + "_Disabled"
        ] = false;

        //sire
        this.fields[
          "pedigreeArray_" + nextGeneration + "_" + sireNextChild + "_Disabled"
        ] = false;
      });

      array[index] = this.pedigreeArray[index];
    });
  },

  methods: {
    resetPedigree() {
      this.confirmSave.dialog = false;
      this.confirmSave.dog = {};
      this.confirmSave.loading = false;
    },

    openPedigree(dog) {
      this.confirmSave.dog = dog;
      this.confirmSave.dialog = true;
    },

    savePedigree() {
      this.confirmSave.loading = true;

      let payload = {
        appId: this.$route.params.id,
        dogId: this.confirmSave.dog.id,
        fields: this.fields,
      };

      this.$store
        .dispatch("lhl/dogs/savePedigree", payload)
        .then(() => {
          this.resetPedigree();
        })
        .catch(() => {
          this.confirmSave.loading = false;
        });
    },

    updateParent: function (event, generation, child) {
      var nextChild = child + 1;

      if (generation < 5) {
        this.fields["pedigreeArray_" + generation + "_" + child] = event.dam_id;
        this.fields["pedigreeArray_" + generation + "_" + nextChild] =
          event.sire_id;

        this.fields[
          "pedigreeArray_" + generation + "_" + child + "_Disabled"
        ] = false;

        this.fields[
          "pedigreeArray_" + generation + "_" + nextChild + "_Disabled"
        ] = false;

        if (
          this.fields["pedigreeArray_" + generation + "_" + child] ||
          this.fields["pedigreeArray_" + generation + "_" + nextChild]
        ) {
          this.updateParent(event, generation + 1, child * 2);
        }
      }
    },
  },

  computed: {
    dog() {
      return this.$store.getters["lhl/dogs/get"];
    },
    dams() {
      let dams = this.$store.getters["lhl/dogs/damsAll"];

      dams = dams.filter((c) => c.id != this.dog.id);

      dams.forEach((dog) => {
        if (dog.kennel_name == null) {
          dog.kennel_name = dog.name;
        }
      });

      return dams;
    },
    sires() {
      let sires = this.$store.getters["lhl/dogs/siresAll"];

      sires = sires.filter((c) => c.id != this.dog.id);

      sires.forEach((dog) => {
        if (dog.kennel_name == null) {
          dog.kennel_name = dog.name;
        }
      });

      return sires;
    },
    pedigreeArray() {
      return this.$store.getters["lhl/dogs/pedigreeArray"];
    },
  },
};
</script>

<style>
#pedigree .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: unset;
  height: 30px;
}

#pedigree .v-text-field--outlined .v-label {
  top: 6px;
}

#pedigree .v-text-field--outlined > .v-input__control > .v-input__slot,
#pedigree .v-text-field--enPedigreed .v-input__append-inner {
  margin-top: 4px;
}

.v-text-field--enclosed .v-input__append-inner {
  margin-top: 4px;
}

button#no-results-btn {
  border: none;
  background: none;
  padding: 0px;
  margin-top: 5px;
  color: #444;
}

table#pedigree td {
  border: 1px solid #000;
  width: 20%;
  padding: 5px;
}
table#pedigree {
  width: 100%;
}
.select2-container--default .select2-selection--single {
  border-radius: 0px !important;
  width: 220px;
}

.theme--light.v-text-field--outlined:not(
    .v-input--is-focused
  ).v-input--is-disabled
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: red;
}

.select_sire {
  background: lightred;
}

.select_dam {
  background: lightblue;
}

.generate_pedigree {
  overflow: auto;
}

.place_for_message {
  width: 100%;
  float: left;
}

#generatePed {
  cursor: pointer;
}

.green_sucsess {
  color: green;
  padding-top: 20px;
  padding-bottom: 20px;
}

.v-input__slot {
  margin-bottom: 0px;
}
/* .append {
  position: sticky;
  bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
} */
</style>
